import React, { useState, useEffect, useRef } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ReactMapGL, { Marker } from 'react-map-gl'
import Modal from 'react-modal'
import * as data from '../data/outreaches.json'

import '../assets/css/motivate.css'
import '../assets/css/mapbox.css'

import BannerTeam from '../components/BannerTeam'


import poster from '../assets/images/motivate/outreach.png'

const style = {
  width: '90vw',
  height: 'auto',
  // borderRadius: '10px',
  // borderStyle: 'solid',
  // borderColor: 'white',
  // borderWidth: '8px'
  boxShadow: '#131520 0 0 20px 10px',
}

export default function Motivate() {
  return (
    <div>
      <Layout>
        <Helmet>
          <title>Krypton Warriors</title>
          <meta name="description" content="Landing Page" />
          <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "53ca0e93ef80463192e735fa104fef39"}'></script>
        </Helmet>

        <BannerTeam />
        <div id="main">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h2>About</h2>
              </header>
              <p>
                Our team started as an FLL team, and 3 years later, we delved
                into FTC. We got introduced to FLL by another mentor from a
                different team. We really got interested in FIRST through
                another team's outreach, and we came together through our shared
                love of STEAM and Robotics. As such, we recognize it as
                imperative that we must share our knowledge and love of robotics
                with as many people as possible. Helping our community is like
                helping ourselves and we learn by teaching others. Sharing the
                beauty of FIRST to other children is amazing and it is always
                one of our biggest duties and tasks throughout our season. We
                reach out to people in our local community, children
                throughout the United States, and even underserved children
                throughout the world, in places like India and Colombia.
              </p>
            </div>
            <div style={{ display: 'grid', placeItems: 'center' }}>
                <img
                  src={poster}
                  style={style}
                  alt=""
                ></img>
                <br></br>
                <br></br>
              </div>
          </section>
        </div>
      </Layout>
    </div>
  )
}
